.OverlayModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--color-modal-overlay-bg);
    backdrop-filter: blur(10px);

    padding: 1rem;

    z-index: 100;

    opacity: 0;
    pointer-events: none;

    transition-delay: 0.1s;
    transition: opacity 0.2s ease-in-out;

    .modal-box {
        transition: transform 0.3s ease-in-out;
        transform: scale(0.9);
    }

    &.show {
        opacity: 1;
        pointer-events: auto;

        .modal-box {
            transform: scale(1);
        }
    }

    .modal-box {
        width: 100%;
        max-width: 30rem;

        border-radius: 0.4rem;

        color: var(--color-text);
        background: var(--color-modal-bg);

        padding: 1rem;

        .modal-header {
            position: relative;
            display: grid;
            grid-template-columns: 1fr auto;
            padding-bottom: 0.6rem;

            h2 {
                margin: 0;
                padding: 0;
                font-size: 1.3rem;
                font-weight: 600;
            }

            .close-button {
                position: absolute;
                top: -0.5rem;
                right: -0.5rem;

                display: flex;
                justify-content: center;
                align-items: center;

                padding: 0;

                cursor: pointer;
                border: 0;

                background: transparent;
                color: var(--color-text);

                width: 2rem;
                height: 2rem;

                &:hover {
                    background: var(--color-tile-bg-hovered);
                }

                .icon {
                    width: 18px;
                    height: 18px;

                    path {
                        fill: var(--color-text);
                    }
                }
            }
        }
    }
}
