.PuzzleScores {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .score {
        position: relative;

        padding: 0.1rem 0.2rem;
        margin-right: 0.4rem;
        margin-block-end: 0.2rem;
        border-radius: 0.3rem;

        font-size: 0.8rem;

        background-color: var(--marker-color);
        color: rgba(0, 0, 0, 0.65);

        font-weight: 500;

        &.total {
            --marker-color: var(--marker-color-incomplete);

            font-size: 1rem;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.9);
        }

        &.marker-0 {
            --marker-color: var(--marker-color-0);
        }

        &.marker-1 {
            --marker-color: var(--marker-color-1);
        }

        &.marker-2 {
            --marker-color: var(--marker-color-2);
        }

        &.marker-3 {
            --marker-color: var(--marker-color-3);
        }

        &.marker-4 {
            --marker-color: var(--marker-color-4);
        }

        &.marker-5 {
            --marker-color: var(--marker-color-5);
        }

        &.marker-6 {
            --marker-color: var(--marker-color-6);
        }

        &.marker-7 {
            --marker-color: var(--marker-color-7);
        }

        &.marker-8 {
            --marker-color: var(--marker-color-8);
        }

        &.marker-9 {
            --marker-color: var(--marker-color-9);
        }
    }
}
