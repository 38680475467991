@keyframes pulse {
    0% {
        transform: scale3d(1, 1, 1);
    }
    50% {
        transform: scale3d(1.2, 1.5, 1);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}

.PuzzleStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 1rem;

    .StyledButton {
        opacity: 0;
        transform: scale(0.9);

        transition:
            opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
            transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &.valid {
            position: relative;
            opacity: 1;
            transform: scale(1);

            z-index: 1;

            &:before {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                border-radius: 0.5rem;
                content: '';
                background-color: var(--marker-color-0);

                opacity: 0.4;
                animation: pulse 2s infinite;

                z-index: -1;
                pointer-events: none;
            }
        }

        &.complete {
            opacity: 1;
            transform: scale(1);
        }
    }
}
