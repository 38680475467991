.PuzzleDescription {
    text-align: center;
    margin-bottom: 1rem;

    line-height: 1.6;

    .date {
        font-weight: bold;
    }

    .number {
        opacity: 0.5;
    }
}
