@keyframes flash {
    0% {
        border: 2px solid var(--marker-color-complete);
        background-color: transparent;
    }
    100% {
        border: 2px solid var(--marker-color-complete);
        background-color: var(--marker-color-complete);
    }
}

.MatchMarkerHighlight {
    position: absolute;
    border-radius: 100px;
    opacity: var(--marker-opacity-incomplete);
    mix-blend-mode: var(--marker-color-mix-mode);
    background-color: var(--marker-color-incomplete);
    z-index: 20;

    pointer-events: none;

    transition-delay: 0.2s;
    transition:
        background-color 0.2s ease-in-out,
        opacity 0.2s ease-in-out,
        left 0.2s ease-in-out,
        top 0.2s ease-in-out,
        width 0.2s ease-in-out,
        height 0.2s ease-in-out;

    &.valid {
        border: 3px solid var(--marker-color-complete);
        pointer-events: all;

        &.dragging-tile {
            pointer-events: none;
        }
    }

    &.complete {
        opacity: var(--marker-opacity-complete);
        background-color: var(--marker-color-complete);

        // animation: flash 0.25s;
        pointer-events: none;
    }

    &.marker-0 {
        --marker-color-complete: var(--marker-color-0);
    }

    &.marker-1 {
        --marker-color-complete: var(--marker-color-1);
    }

    &.marker-2 {
        --marker-color-complete: var(--marker-color-2);
    }

    &.marker-3 {
        --marker-color-complete: var(--marker-color-3);
    }

    &.marker-4 {
        --marker-color-complete: var(--marker-color-4);
    }

    &.marker-5 {
        --marker-color-complete: var(--marker-color-5);
    }

    &.marker-6 {
        --marker-color-complete: var(--marker-color-6);
    }

    &.marker-7 {
        --marker-color-complete: var(--marker-color-7);
    }

    &.marker-8 {
        --marker-color-complete: var(--marker-color-8);
    }

    &.marker-9 {
        --marker-color-complete: var(--marker-color-9);
    }
}
