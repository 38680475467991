.StyledButton {
    border: none;
    padding: 0.6rem 1rem;

    background-color: var(--marker-color-4);
    color: black;

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;

    font-weight: 600;

    cursor: pointer;

    border-radius: 0.5rem;
    transition-duration: 0.4s;

    &:hover {
        background-color: var(--marker-color-5);
    }

    &:disabled {
        background-color: var(--color-board-bg);
    }

    &.secondary {
        background: transparent;
        text-decoration: underline;
        color: var(--color-text);

        &:hover {
            background-color: var(--color-board-bg);
        }
    }
}
