.GameHistoryDays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 0.5rem;

    .puzzle-history {
        display: flex;
        flex-direction: column;
        align-items: center;

        .board-wrapper {
            display: flex;
            justify-content: center;

            .board {
                padding: 4px;
                margin-bottom: 0.4rem;

                background: var(--color-board-bg);

                .LetterTile {
                    color: transparent !important;
                }
            }
        }

        .empty-board {
            .icon {
                path {
                    opacity: var(--marker-opacity-incomplete);
                    fill: var(--marker-color-incomplete);
                }
            }
        }

        .puzzle-history-info {
            text-align: center;
            font-size: 0.8rem;
            font-weight: 500;

            .puzzle-history-index {
                margin-bottom: 0.4rem;
                opacity: 0.6;
            }

            .puzzle-history-score {
                padding: 0.1rem 0.2rem;
                border-radius: 0.3rem;

                font-weight: 700;
                background-color: var(--marker-color-incomplete);
                color: rgba(0, 0, 0, 0.9);
            }
        }
    }
}
