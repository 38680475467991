.LetterTile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: var(--tile-size);
    height: var(--tile-size);
    text-transform: uppercase;

    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;

    user-select: none;

    border-radius: 2px;

    transition:
        background-color 0.05s linear,
        left 0.2s ease-in-out,
        top 0.2s ease-in-out,
        transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        box-shadow 0.25s linear;

    transform: translate3d(0, 0, 0);

    color: var(--color-tile-text);
    background: var(--color-tile-bg);

    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;

    .letter {
        font-size: var(--font-size);
    }

    .score {
        height: 1em;
        width: 1em;

        text-align: center;

        font-size: calc(var(--font-size) * 0.6);
        position: absolute;
        bottom: calc(var(--tile-size) * 0.05);
        right: calc(var(--tile-size) * 0.05);
        opacity: 0.3;

        svg {
            height: 1em;
            width: 1em;
            fill: var(--color-tile-text);
        }
    }

    &.locked {
        background: var(--color-tile-bg-locked);

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            box-shadow: inset 0 0 1em 1px var(--color-tile-shadow-locked);
        }

        .letter {
            opacity: 0.5;
        }
    }

    &.interactive {
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                background: var(--color-tile-bg-active);
                box-shadow: 0 0 5px 3px var(--color-tile-shadow);
                transform: translate3d(0, -2px, 0);
                z-index: 1;
            }
        }
    }

    &.dragging {
        color: transparent;
        background: var(--color-tile-bg-dragging);
    }

    &.hovered {
        background-color: var(--color-tile-bg-hovered);
    }

    &.active {
        background: var(--color-tile-bg-active);

        box-shadow:
            0 2px 15px 5px var(--color-tile-shadow-light),
            0 1px 5px 2px var(--color-tile-shadow) !important;
        transform: translate3d(0, -3px, 0) scale(1.2) rotate(var(--active-rotation)) !important;
        z-index: 5;

        transition:
            left 0.2s ease-in-out,
            top 0.2s ease-in-out,
            transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275),
            box-shadow 0.05s ease-in-out;
    }
}
