.VictoryModal {
    text-align: center;

    .modal-box {
        padding-bottom: 2rem;
    }

    .time {
        font-weight: bold;
    }

    .PuzzleDescription {
        .date {
            font-size: 1rem;
            font-weight: normal;
            opacity: 0.5;
        }

        .number {
            display: none;
        }
    }

    .small-board-wrapper {
        display: flex;
        justify-content: center;

        margin-top: 1.2rem;
        margin-bottom: 1rem;

        .small-board-container {
            padding: 10px;
            background: var(--color-board-bg);

            .LetterTile {
                color: transparent !important;

                .score {
                    height: 2em;
                    width: 2em;

                    bottom: calc(50% - 1em);
                    right: calc(50% - 1em);

                    opacity: 1;

                    svg {
                        height: 2em;
                        width: 2em;
                        fill: var(--color-tile-text);
                    }
                }
            }
        }
    }

    .PuzzleScores {
        justify-content: center;
        margin-bottom: 1rem;
    }

    .GameHistoryDays {
        margin-top: 1rem;
    }

    .game-history-stats {
        border-top: 1px solid var(--color-modal-divider);

        h3 {
            margin-top: 1rem;
            margin-bottom: 1rem;

            font-weight: normal;
            font-size: 1rem;
            opacity: 0.5;
        }
    }
}
