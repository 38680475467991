.HelpModal {
    .goal {
        font-weight: 600;
        font-size: 1.1rem;
    }

    .small-board-wrapper {
        display: flex;
        justify-content: center;

        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .small-board-container {
        padding: 10px;
        background: var(--color-board-bg);
    }

    .explain-icon {
        display: inline-block;
        width: 0.8em;
        height: 0.8em;
        margin-right: 0.2em;
        fill: var(--color-tile-text);
    }
}
