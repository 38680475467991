@keyframes background-rainbow {
    0% {
        background-color: var(--marker-color-0);
    }
    8.33% {
        background-color: var(--marker-color-1);
    }
    16.67% {
        background-color: var(--marker-color-2);
    }
    25% {
        background-color: var(--marker-color-3);
    }
    33.33% {
        background-color: var(--marker-color-4);
    }
    41.67% {
        background-color: var(--marker-color-5);
    }
    50% {
        background-color: var(--marker-color-6);
    }
    58.33% {
        background-color: var(--marker-color-7);
    }
    66.67% {
        background-color: var(--marker-color-8);
    }
    75% {
        background-color: var(--marker-color-9);
    }
    83.33% {
        background-color: var(--marker-color-10);
    }
    91.67% {
        background-color: var(--marker-color-11);
    }
    100% {
        background-color: var(--marker-color-0);
    }
}

.AppHeader {
    display: grid;
    grid-template-columns: 3rem 1fr 6rem;
    align-items: center;

    background: var(--color-board-bg);

    box-shadow: 0 0.2rem 0.6rem 0.2rem var(--color-tile-shadow);

    z-index: 50;

    .tools {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-title {
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            position: relative;

            left: 0.5rem;

            text-align: center;
            padding: 0;
            margin: 0;
            font-weight: 500;
            letter-spacing: 1rem;
            font-size: 1rem;
            line-height: 1rem;

            .bar {
                position: absolute;

                border-radius: 100px;

                mix-blend-mode: var(--marker-color-mix-mode);
                background-color: var(--marker-color-incomplete);

                opacity: 0;
                transition: opacity 0.3s ease-in-out;

                &.horizontal {
                    top: -0.5rem;
                    left: -1.6rem;
                    width: calc(100% + 2.2rem);
                    height: 2rem;
                }
            }

            &.complete {
                .bar {
                    opacity: var(--marker-opacity-complete);
                    animation: background-rainbow 25s infinite;
                }
            }
        }
    }

    button {
        display: flex;

        justify-content: center;
        align-items: center;

        width: 3rem;
        height: 3rem;

        border: 0;
        border-radius: 3px;

        background: transparent;
        color: var(--color-text);
        cursor: pointer;

        &:hover {
            background: var(--color-tile-bg-hovered);
        }
    }

    .icon {
        width: 24px;
        height: 24px;

        opacity: 0.6;

        path {
            fill: var(--color-text);
        }
    }
}
