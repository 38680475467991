.ToggleSwitchButton {
    --knob-size: 1.6rem;

    display: inline-block;
    position: relative;

    border: 0;
    background-color: #ddd;
    width: calc(var(--knob-size) * 2);
    height: calc(var(--knob-size) + 4px);

    border-radius: var(--knob-size);

    overflow: hidden;

    .knob {
        position: absolute;
        top: 2px;
        left: 2px;

        background-color: white;
        width: var(--knob-size);
        height: var(--knob-size);
        border-radius: var(--knob-size);
        transition: transform 0.3s;

        transform: translateX(0);

        box-shadow: 0 2px 10px 2px var(--color-tile-shadow);
    }

    &.active {
        background-color: #4caf50;

        .knob {
            transform: translateX(calc(100% - 4px));
        }
    }
}
