.StatsModal {
    .stats-row {
        padding: 0.8rem 0;
        border-bottom: 1px solid var(--color-modal-divider);

        &:last-of-type {
            border-bottom: none;
        }

        .stats-title {
            font-weight: 600;
            padding-bottom: 0.5rem;
        }

        .sub-stats-row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 0.2rem 0 0.2rem 1.6rem;
        }
    }
}
