.SettingsModal {
    .settings-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        border-bottom: 1px solid var(--color-modal-divider);

        &:last-of-type {
            border-bottom: none;
        }
    }

    .basic-link {
        color: var(--color-text);
    }

    .ToggleSwitchButton {
        margin-right: 1rem;
    }
}
