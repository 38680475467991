.App {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3rem 1fr;

    height: 100dvh;
    width: 100vw;

    margin: 0 auto;

    background: var(--color-board-bg);

    .main-game-container {
        display: flex;
        flex-direction: column;

        justify-content: center;

        margin: 0 auto;

        width: 100%;
        max-width: 500px;

        padding: 15px;
    }

    .game-board-container {
        width: 100%;
        aspect-ratio: 1 / 1;

        margin-top: 1rem;
    }
}
