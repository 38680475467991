body {
    margin: 0;

    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
    font-variation-settings: 'slnt' 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    touch-action: none;

    color: var(--color-text);
    background: var(--color-board-bg);

    --color-text: #000;
    --color-board-bg: #eee;

    --color-modal-divider: rgba(0, 0, 0, 0.1);

    --color-modal-overlay-bg: rgba(0, 0, 0, 0.5);
    --color-modal-bg: #fff;

    --color-tile-bg: #fff;
    --color-tile-bg-locked: #eee;
    --color-tile-bg-submittable: #fcf3c5;
    --color-tile-bg-active: #fff;
    --color-tile-bg-dragging: rgba(0, 0, 0, 0.1);
    --color-tile-bg-hovered: rgba(0, 0, 0, 0.15);

    --color-tile-shadow: rgba(0, 0, 0, 0.1);
    --color-tile-shadow-light: rgba(0, 0, 0, 0.05);
    --color-tile-shadow-locked: rgba(0, 0, 0, 0.3);
    --color-tile-text: #000;

    --marker-color-mix-mode: multiply;
    --marker-opacity-incomplete: 1;
    --marker-opacity-complete: 0.8;

    --marker-color-incomplete: rgba(0, 0, 0, 0.2);
    --marker-color-valid: rgba(0, 0, 0, 0.2);

    --marker-color-0: #dc79aa;
    --marker-color-1: #ea976b;
    --marker-color-2: #f1bd64;
    --marker-color-3: #bfdf76;
    --marker-color-4: #7bdab4;
    --marker-color-5: #76d1df;
    --marker-color-6: #79acdc;
    --marker-color-7: #9380d5;
    --marker-color-8: #ca7fd6;
    --marker-color-9: #f3c2a0;
    --marker-color-10: #9de2b3;
    --marker-color-11: #83ccee;
    --marker-color-12: #e7a9c4;

    &.theme-dark {
        --color-text: #fff;
        --color-board-bg: #222;

        --color-modal-divider: rgba(255, 255, 255, 0.1);

        --color-modal-overlay-bg: rgba(0, 0, 0, 0.6);
        --color-modal-bg: #333;

        --color-tile-bg: #333;
        --color-tile-bg-locked: #222;
        --color-tile-bg-active: #444;
        --color-tile-bg-dragging: rgba(0, 0, 0, 0.1);
        --color-tile-bg-hovered: rgba(0, 0, 0, 0.15);
        --color-tile-shadow: rgba(0, 0, 0, 0.2);
        --color-tile-shadow-light: rgba(0, 0, 0, 0.1);
        --color-tile-shadow-locked: rgba(0, 0, 0, 0.7);

        --color-tile-text: #fff;

        --marker-color-mix-mode: lighten;
        --marker-opacity-incomplete: 0.3;
        --marker-opacity-complete: 0.6;

        --marker-color-incomplete: #999;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    box-sizing: border-box;
}
